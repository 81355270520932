import * as React from 'react';
import {MenuItemLink} from 'react-admin';
import {useProjects} from './useProjects';

export function ProjectMenu({ onMenuClick }) {
  const reports = useProjects();

  return (
    <div>
      {reports.map((report) => (
        <MenuItemLink
          key={report.name}
          to={`/project/${report.id}`}
          primaryText={report.name}
          onClick={onMenuClick}
        />
      ))}
    </div>
  );
}
