import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { keycloak } from './auth';
import * as serviceWorker from './serviceWorker';

keycloak.init({
  onLoad: 'login-required',
  checkLoginIframe: false
})
  .then(function (authenticated) {
    ReactDOM.render(
      <React.StrictMode>
        <App/>
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch(function () {
    alert('Failed to initialize');
  });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
