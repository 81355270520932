import * as React from 'react';
import {Admin, Resource} from 'react-admin';
import {authProvider} from "./auth";
import {customRoutes} from './customRoutes';
import {dataProvider} from './dataProvider';
import {Layout} from './Layout';
import {ProjectList} from './project/ProjectList';
import { theme } from "./theme";

export function App() {
  return (
    <Admin
      theme={theme}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={Layout}
    >
      <Resource name={`reports`} list={ProjectList} />
    </Admin>
  );
}
