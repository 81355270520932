export const config = {
  api: {
    url: process.env.REACT_APP_API_URI
  },
  keycloak: {
    url: "https://keycloak.ipsos-reporting.com/auth",
    realm: "nfieldgate",
    clientId: "nfieldgate",
  }
}

