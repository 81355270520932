import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

export function useProjects() {
  const dataProvider = useDataProvider();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    dataProvider
      .getList('reports', { pagination: {} })
      .then((response) => setReports(response.data))
  }, [dataProvider]);

  return reports;
}
