import * as React from "react";
import {AppBar as RAAppBar} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const AppBar = props => {
  const classes = useStyles();
  return (
    <RAAppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}>
        IPSOS – EGIS: přehled projektů
      </Typography>
      <span className={classes.spacer}/>
    </RAAppBar>
  );
};

export default AppBar;
