import { Button } from '@material-ui/core';
import { amber, green, grey, lightBlue, orange } from '@material-ui/core/colors';
import { GetApp } from '@material-ui/icons';
import fileSaver from 'file-saver';
import { json2csv } from 'json-2-csv';
import keyBy from 'lodash/keyBy';
import React from 'react';

import { Datagrid, Loading, NumberField, TextField, useQuery } from 'react-admin';
import { Scorecard } from "../components/ScoreCard";

// https://support.nipo.com/OnlineDocumentation/NIPO%20ODIN%205.17%20Scripter%27s%20Guide/index.htm?toc.htm?5890.htm
const colorCodding = {
  0: amber,
  1: lightBlue,
  2: lightBlue,
  3: lightBlue,
  4: grey,
  5: grey,
  6: lightBlue,
  7: lightBlue,
  8: orange,
  9: orange,
  10: orange,
  11: orange,
  12: orange,
  13: grey,
  14: grey,
  15: grey,
  16: grey,
  17: grey,

  18: green,
  19: green,
  20: green,
  21: green,
  22: green,

  90: green,

  101: grey

}

const rowStyle = (record) => {
  const color = colorCodding[record.ResponseCode] ? colorCodding[record.ResponseCode][800] : 'white';
  return ({
    borderLeft: `5px solid ${color}`
  });
}

function exportCSV(id, data) {
  const filename = (data[0]['nazev_projektu'] || id) + "-export.csv"

  function exportCallback(err, csv) {
    if (err) {
      console.error(err)
      return
    }

    const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
    fileSaver.saveAs(blob, filename);
  }

  json2csv(data, exportCallback, {
    keys: [
      {field: 'Predcislo', title: 'Předčíslí'},
      {field: 'ucet', title: 'Číslo účtu'},
      {field: 'banka', title: 'Kód banky'},
      {field: 'pocet_vyplnenych_karet', title: 'Počet vyplněných karet'},
      {field: 'jmeno_dtb', title: 'Jméno'},
      {field: 'prijmeni_dtb', title: 'Příjmení'},
      {field: 'dotaznik_zpusob_vyplaty', title: 'Forma výplaty odměny '},
      {field: 'nazev_projektu', title: 'Název projektu'},
      {field: 'cas_vyplneni', title: 'Čas vyplnění'},
    ],
    excelBOM: true,
    delimiter: {
      field: ";"
    },
    emptyFieldValue: ""
  })
}

export function InterviewList({
                                match: {
                                  params: {id},
                                },
                              }) {
  const {data, loading, error} = useQuery({
    type: 'GET_LIST',
    resource: `reports/${id}/interviews`,
    payload: {
      pagination: {},
      filter: {},
    },
  });

  if (loading) {
    return <Loading/>;
  }
  if (error) {
    return <p>ERROR: {error}</p>;
  }

  const surveys = {
    current: data.filter(r => r.Result === "Successful").length,
    total: data.length
  }

  const cards = data.reduce((acc, row) => ({
    current: acc.current + parseInt(row.pocet_vyplnenych_karet || 0),
    total: acc.total + parseInt(row.pocet_karet_dtb),
  }), {
    current: 0,
    total: 0
  })

  return (
    <>
      <div style={{display: "flex", marginBottom: 20}}>
        <Scorecard title="Vyplněných dotazníků" {...surveys} />
        <Scorecard title="Vyplněných karet" {...cards}/>
        <div style={{flexGrow: 1}}/>
        <div>
          <Button title="Export CSV"
                  startIcon={<GetApp/>}
                  disabled={loading || error}
                  onClick={() => exportCSV(id, data)}>
            Export CSV
          </Button>
        </div>
      </div>

      <Datagrid
        data={keyBy(data, 'InterviewId')}
        ids={data.map(({id}) => id)}
        currentSort={{field: 'id', order: 'ASC'}}
        selectedIds={[]}
        rowStyle={rowStyle}
      >
        <TextField source="InterviewId" label="Id" sortable={false}/>
        <TextField source="jmeno_dtb" label="Jméno" sortable={false}/>
        <TextField source="prijmeni_dtb" label="Příjmení" sortable={false}/>
        <TextField source="Result" label="Stav" sortable={false}/>
        <NumberField source="pocet_karet_dtb" label="Počet karet" sortable={false}/>
        <NumberField source="pocet_vyplnenych_karet" label="Vyplněných karet" sortable={false}/>

      </Datagrid>
    </>
  );
}
