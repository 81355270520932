import * as React from 'react';
import {Datagrid, List, TextField, useRedirect,} from 'react-admin';

export function ProjectList(props) {
  const redirect = useRedirect();

  return (
    <List {...props} pagination={false} bulkActionButtons={false} actions={null}>
      <Datagrid
        rowClick={(id, path, row) => {
          redirect(`/project/${row.id}`);
        }}
      >
        <TextField source="name" label="Vyberte projekt" sortable={false}/>
      </Datagrid>
    </List>
  );
}
