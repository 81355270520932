import Keycloak from "keycloak-js";
import { AUTH_ERROR, AUTH_LOGOUT } from 'react-admin';
import { config } from "./config";

export const keycloak = new Keycloak(config.keycloak);

function logout() {
  return keycloak.logout();
}

function authError(params) {
  const status = params.status;
  if (status === 401 || status === 403) {
    console.error('Auth error', status)
    return Promise.reject();
  }
  return Promise.resolve();
}

const authFunctions = {
  [AUTH_LOGOUT]: logout,
  [AUTH_ERROR]: authError,
}

export function authProvider(type, params) {
  const authFunction = authFunctions[type]
  if (authFunction) {
    return authFunction(params)
  }

  return Promise.resolve()
}
