import lightBlue from "@material-ui/core/colors/lightBlue";
import teal from "@material-ui/core/colors/teal";
import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue['700'],
      dark: lightBlue['900'],
      light: lightBlue['200'],
    },

    secondary: {
      main: teal['500'],
      light: teal['200'],
      dark: teal['900'],
    },
  },
  sidebar: {
    width: 240,
    closedWidth: 0,
  },
});
