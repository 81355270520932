import jsonServerProvider from 'ra-data-springboot-rest/src';
import {
  fetchUtils
} from "react-admin";
import { config } from "./config";
import {keycloak} from './auth';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = keycloak.token;
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = jsonServerProvider(config.api.url, httpClient);
