import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) =>({
  root: {
    minHeight: 80,
    minWidth: 220,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative'
  },
  header: {
    minHeight: 20,
    textAlign:"left",
    paddingBottom: theme.spacing.unit,

  },
  footer: {
    minHeight: 30,
  },
  content: {
    marginTop: theme.spacing.unit,
    textAlign: "center"
  },

  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  value: {
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
  },
  note: {
    textAlign: 'right',
    borderTop: ' 1px solid grey',
    position: 'absolute',
    bottom: theme.spacing.unit/2,
    width: `calc( 100% - ${theme.spacing.unit * 2}px )`,
    right: theme.spacing.unit,
    maxHeight: theme.spacing.unit * 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

}))

const formatter = new Intl.NumberFormat('cs-cz', { style:"percent" })

export function Scorecard({title, current, total}) {
  const classes = useStyles();
  const rate = total === 0 ? 0 : current / total
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography variant="h5" className={classes.value}>
          <span title="Vyplněno">{current}</span>
          &nbsp;/&nbsp;
          <span title="Celkem">{total}</span>
        </Typography>
      </div>
      <div className={classes.footer}>
        <Typography variant="caption" className={classes.note}>
          {formatter.format(rate)}
        </Typography>
      </div>
    </Paper>
  );
}
